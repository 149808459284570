.layout {
  overflow: hidden;

  .site {
    margin: 0 auto;
  }

  @media (max-width: 1024px) {
    .site {
      position: relative;
    }

    &.menuOpen {
      .site > div {
        flex-direction: column;
      }

      .site,
      button,
      header {
        z-index: 10;
        animation: slide-in 0.5s forwards;
      }
    }

    &.menuClosed {
      .site > div {
        flex-direction: row;
      }

      .site,
      button,
      header {
        animation: slide-out 0.3s forwards;
      }
    }
  }
}

@keyframes slide-in {
  0% {
    left: 0;
  }

  100% {
    left: 15.3571rem;
  }
}

@keyframes slide-out {
  0% {
    left: 15.3571rem;
  }

  100% {
    left: 0;
  }
}
