.disclaimerLink a:first-of-type::after,
.disclaimerLink span:first-of-type::after {
  content: "\1C0";
  margin: 0 5px;
}

.disclaimerLink span:last-child::after {
  content: "";
  margin: 0;
}
